.content {
  .rations {
      margin: 1vw auto;
      width: 80%;
      
      img {
          height: 20vw;
          min-height: 200px;
          max-height: 500px;
          width: auto;
      }
    }
  .example {
      border: 1px solid black;
      padding: 1rem 1vw;
    &.example1 {
      width: 1100px;
      @media only screen and (max-width: 1300px) {
        width: 900px;
      }
    }
    &.example2 {
        width: 1300px;
        @media only screen and (max-width: 1300px) {
            width: 900px;
          }
      }
    &.example3 {
        width: 1300px;
        @media only screen and (max-width: 1300px) {
            width: 900px;
          }
      }
    .example3 {
         text-align: center;
         position: relative;
         left: -1rem;
         img {
             max-width: 1000px;
             min-width: 800px;
             width: 90%;
             height: auto;
         }  
    }
    margin-left: 2%;
    .example-name {
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      padding-left: 1.3rem;
      padding-bottom: 0.4rem;
    }
    img {
      width: 400px;
      height: auto;
    }
    div:nth-child(2) {
      &.row-text {
        margin: auto 0 auto 0;
      }
      margin: auto 0 auto 2rem;
      @media only screen and (max-width: 1300px) {
        .minor {
            font-size: 1rem;
        }
      }
    }
  }
}
