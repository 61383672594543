.content {
    .chart-container {
        width: 810px;
        margin: auto;
        
    .flex3column {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.1rem;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.05rem;
        
        div.first {
            width: 50px;
            font-size: 2rem;
            line-height: 1.6rem;
            flex-grow: 0;
            color: black;
            box-sizing: border-box;
            margin: 0 10px 0 10px;
            padding: 20px 0 20px 0;
        }
        div.second {
            width: 340px;
            flex-grow: 0;
            color: white;
            background-color: #4b5a61;
            border: 1px solid black;
            box-sizing: border-box;
            margin: 0 15px 0 10px;
            padding: 21px 0 19px 0;
            text-shadow: -1px -1px 1px rgba(225,255,255,0.2), 1px 1px 1px rgba(0,0,0,0.1), 2px 2px 1px rgba(0,0,0,0.1);
            &:empty {
                background-color: white;
                border: 0;
            }
        }
        div.third {
            width: 340px;
            flex-grow: 0;
            color: white;
            background-color: #b85a0f;
            border: 1px solid black;
            box-sizing: border-box;
            margin: 0 10px 0 17px;
            padding: 21px 0 19px 0;
            text-shadow: -1px -1px 1px rgba(225,255,255,0.1), 1px 1px 1px rgba(0,0,0,0.1), 2px 2px 1px rgba(0,0,0,0.05);
            &:empty {
                background-color: white;
                border: none;
            }
        }

        div.both {
            width: 680px;
            flex-grow: 0;
            color: white;
            background-color: #888;
            border: 1px solid black;
            box-sizing: border-box;
            margin: 0 10px 0 17px;
            padding: 21px 0 19px 0;
            text-shadow: -1px -1px 1px rgba(225,255,255,0.1), 1px 1px 1px rgba(0,0,0,0.1), 2px 2px 1px rgba(0,0,0,0.05);
            &:empty {
                display: none;
            }
        }


        div.line {
            width: 800px;
            height: 2px;
            background-color: #ccc;
            margin: 10px 0 10px 0;
        }
        div.ind {
            color: black;
            background-color: transparent;
            font-size: 1.5rem;
            padding: 0;
            position: relative;
            top: 10px;
            border: none;
            text-shadow: none;
       }
    }
}
}