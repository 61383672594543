@charset "UTF-8";
.efs {
  font-family: arial, helvetica, sans-serif;
  font-size: 20px;
  /*
  <div className="gray-box">
 <div className="subsubheader">COLOR CODES</div>
  <div className="two-column-flex">
      <div className="color-code-image"><img src={c1} alt="attack factor is red" /></div>
      <div className="color-code-text">
          <div><span>Attack factor is red:</span> Armored unit.</div>
          <div><span>Movement in red box:</span> Unit is motorized.
      </div>
  </div>
</div>
*/ }
  .efs .b {
    font-weight: bold; }
  .efs .content {
    margin: 0 5px 100px 0px;
    padding-left: 30px; }
    .efs .content .stand-out {
      font-size: 125%;
      font-weight: bold;
      padding: 0 0 2px 0; }
      .efs .content .stand-out.green {
        color: #088308;
        text-shadow: -1px -1px rgba(100, 255, 148, 0.5), 1px 1px rgba(0, 0, 0, 0.3); }
      .efs .content .stand-out.red {
        color: red;
        text-shadow: -1px -1px rgba(255, 155, 148, 0.5), 1px 1px rgba(0, 0, 0, 0.3); }
    .efs .content .image-caption {
      display: flex;
      width: auto;
      flex-direction: column;
      align-items: center;
      border: 1px solid #aaa;
      background-color: #e6e6e6;
      text-align: center;
      font-size: 1.3rem; }
      .efs .content .image-caption img {
        height: auto;
        width: auto; }
      .efs .content .image-caption div {
        flex-grow: 0;
        display: inline-block; }
      .efs .content .image-caption.flotilla {
        width: 644px;
        padding-bottom: 3px; }
    .efs .content .slight-bold {
      text-shadow: 0 0 0 rgba(0, 0, 0, 0.7); }
    .efs .content .italic {
      font-style: italic; }
    .efs .content .comment-boxes {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 10px; }
      .efs .content .comment-boxes > div {
        margin: 10px; }
    .efs .content .title_img_comment_box {
      margin-left: 50%;
      transform: translateX(-50%);
      display: inline-block;
      text-align: center;
      flex-direction: row;
      justify-content: center;
      background: #eeeeee;
      border: 1px solid black;
      padding: 4px; }
      .efs .content .title_img_comment_box .title {
        font-weight: bold;
        font-size: 1.3rem;
        text-align: center;
        padding: 5px 0 7px 0; }
      .efs .content .title_img_comment_box .image {
        text-align: center;
        width: auto; }
      .efs .content .title_img_comment_box .comment {
        padding: 5px 10px;
        font-size: 1.1rem;
        white-space: nowrap; }
    .efs .content .content-title {
      display: flex;
      font-size: 2rem;
      font-weight: bold;
      padding: 2rem 0 1rem 0; }
      .efs .content .content-title div:nth-child(1) {
        margin-right: 0.5rem; }
      .efs .content .content-title div:last-child {
        flex-grow: 1;
        position: relative;
        top: -0.4rem;
        border-bottom: 2px solid black; }
    .efs .content .clipart {
      margin: auto;
      width: auto;
      text-align: center;
      padding-bottom: 0.6rem; }
      .efs .content .clipart img {
        max-height: 400px;
        height: auto; }
    .efs .content p {
      font-size: 1.3rem;
      margin: 12px 0 15px 0; }
    .efs .content .pdiv {
      font-size: 1.3rem; }
    .efs .content .subheader-counter {
      display: flex;
      font-size: 1.4rem;
      font-weight: bold;
      margin-bottom: 0.5rem; }
      .efs .content .subheader-counter div {
        margin-top: auto;
        position: relative;
        top: 4px; }
      .efs .content .subheader-counter img {
        margin-left: 1.5rem; }
    .efs .content .subheader-normal {
      font-size: 1.3rem;
      font-weight: bold; }
    .efs .content .subheader {
      padding-top: 2rem;
      font-size: 1.5rem;
      font-weight: bold; }
    .efs .content .subheader2 {
      padding-top: 2rem;
      font-size: 1.4rem;
      font-weight: bold; }
    .efs .content .subheader1_4 {
      padding-top: 2rem;
      padding-bottom: 0.5rem;
      font-size: 1.4rem;
      font-weight: bold; }
    .efs .content .subsubheader2 {
      padding-top: 1rem;
      font-size: 2rem;
      font-weight: bold;
      text-align: center; }
    .efs .content .centered {
      text-align: center; }
    .efs .content .indent1rem {
      margin-left: 1rem; }
    .efs .content .gray-box {
      clear: all;
      background-color: #edeeed;
      display: inline-block;
      margin: 0 0 0 5rem;
      font-size: 1.3rem;
      border: 1px solid #555; }
      .efs .content .gray-box .subsubheader {
        font-weight: bold;
        position: relative;
        padding-right: 10px;
        padding-left: 10px;
        white-space: nowrap; }
      .efs .content .gray-box ul {
        list-style: none;
        position: relative;
        margin: 0; }
      .efs .content .gray-box ul li:before {
        content: "•";
        font-size: 110%;
        /* or whatever */
        padding-right: 15px; }
    .efs .content .indented-box {
      border: 1px solid black;
      display: inline-block;
      margin-left: 20px;
      padding: 6px 10px;
      font-size: 20px; }
      .efs .content .indented-box.background-green {
        background-color: #e2ffe2;
        text-shadow: 0 0 rgba(0, 255, 0, 0.9); }
      .efs .content .indented-box.background-blue {
        background-color: #c8d8e6;
        text-shadow: 0 0 rgba(55, 55, 155, 0.9); }
      .efs .content .indented-box.background-red {
        background-color: #e8d8d6;
        text-shadow: 0 0 rgba(155, 55, 55, 0.9); }
      .efs .content .indented-box.background-beige {
        background-color: #efefe0;
        text-shadow: 0 0 rgba(110, 110, 55, 0.9); }
      .efs .content .indented-box.background-yellow {
        background-color: #ffffd0;
        text-shadow: 0 0 rgba(127, 127, 0, 0.9); }
      .efs .content .indented-box.background-pink {
        background-color: #ffdddd;
        text-shadow: 0 0 rgba(127, 127, 0, 0.9); }
      .efs .content .indented-box .indented-box-title {
        text-shadow: 0.01rem 0.01rem black;
        margin-right: 5px; }
      .efs .content .indented-box ul {
        margin: 5px 5px 2px 40px;
        padding: 0; }
      .efs .content .indented-box ol.spaced-ol {
        position: relative;
        top: -14px;
        left: -35px; }
        .efs .content .indented-box ol.spaced-ol li {
          margin: 5px 0; }
        .efs .content .indented-box ol.spaced-ol li:first-child {
          margin-top: 0px; }
    .efs .content .span-note {
      color: green; }
    .efs .content .indented-box-with-image-to-left {
      border: 1px solid black;
      display: inline-block;
      margin-left: 50px;
      padding: 6px 10px;
      font-size: 20px; }
      .efs .content .indented-box-with-image-to-left > div:first-child {
        font-weight: bold;
        margin-right: 5px; }
      .efs .content .indented-box-with-image-to-left img {
        height: auto; }
      .efs .content .indented-box-with-image-to-left ul {
        margin: 5px 5px 2px 40px;
        padding: 0; }
    .efs .content .box-with-image-to-left {
      border: 1px solid black;
      display: inline-block;
      padding: 6px 10px;
      font-size: 20px; }
      .efs .content .box-with-image-to-left > div:first-child {
        font-weight: bold;
        margin-right: 5px; }
      .efs .content .box-with-image-to-left img {
        height: auto; }
      .efs .content .box-with-image-to-left ul {
        margin: 5px 5px 2px 40px;
        padding: 0; }
  .efs .image-text {
    display: flex;
    margin-left: 20px;
    padding: 10px 5px 10px 5px;
    background-color: #ffdddd; }
    .efs .image-text ul {
      margin: 0; }
    .efs .image-text img {
      align-self: center;
      margin-left: 10px; }
    .efs .image-text > div {
      margin-left: 20px; }
    .efs .image-text.background-pink {
      border: 1px solid #eedddd;
      background-color: #ffdddd; }
    .efs .image-text.background-grayblue {
      border: 1px solid #ccccdd;
      background-color: #ddddee; }
  .efs .colored-box {
    margin-left: 20px;
    padding: 10px 5px 10px 0px;
    background-color: #ffdddd; }
    .efs .colored-box ul {
      margin: 5px 5px 0px 20px; }
      .efs .colored-box ul li {
        margin-top: 3px; }
    .efs .colored-box img {
      align-self: center;
      margin-left: 10px; }
    .efs .colored-box div.primary-heading {
      margin-left: 15px;
      text-shadow: 0 0 rgba(0, 0, 0, 0.4);
      letter-spacing: 0.01rem; }
    .efs .colored-box div.secondary-heading {
      margin-left: 37px;
      margin-top: 8px; }
    .efs .colored-box div.postscript {
      margin: 15px 5px 5px 15px; }
  .efs .small-counters-left {
    padding: 0 0 0 5px;
    border: 1px solid #cdd;
    background-color: #dee8e8; }
    .efs .small-counters-left img {
      position: relative;
      top: 3px; }
    .efs .small-counters-left span {
      position: relative;
      top: -29px;
      padding: 0 0 0 10px; }
  .efs .small-counters-left2 {
    display: flex;
    border: 1px solid #cdd;
    background-color: #E1E6E6;
    padding-right: 5px;
    max-width: 846px; }
    .efs .small-counters-left2 div:first-child {
      margin: 10px 10px 3px 10px; }
    .efs .small-counters-left2 div:nth-child(2) {
      margin: auto; }
  .efs .rules-note {
    display: inline-block;
    margin-top: 2px;
    border-radius: 16px;
    border: 1px solid #cfdfdf;
    padding: 6px 10px 5px 10px;
    background-color: #d5e9f5;
    color: #333;
    text-shadow: 0 0 rgba(0, 0, 100, 0.4);
    font-size: 96%;
    box-shadow: 2px 4px 8px 2px rgba(0, 0, 60, 0.2); }
  .efs .rules-clarification {
    display: inline-block;
    margin-top: 2px;
    border-radius: 16px;
    border: 1px solid #d3f0d5;
    padding: 6px 10px 5px 10px;
    background-color: #e5ffe0;
    color: #333;
    text-shadow: 0 0 rgba(0, 110, 0, 0.5);
    font-size: 96%;
    box-shadow: 2px 4px 8px 2px rgba(0, 60, 0, 0.2); }
  .efs .rule-ref {
    color: red;
    font-size: 1rem; }
  .efs .normal-text-n-images {
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin: 12px 0; }
    .efs .normal-text-n-images.bold {
      font-weight: bold; }
    .efs .normal-text-n-images div {
      align-self: flex-end; }
    .efs .normal-text-n-images img {
      align-self: center;
      height: auto; }
  .efs .subheader-n-images {
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin: 12px 0; }
    .efs .subheader-n-images div {
      align-self: flex-end;
      font-size: 1.4rem; }
    .efs .subheader-n-images img {
      align-self: flex-end;
      height: auto;
      margin-left: 10px; }
    .efs .subheader-n-images .image-caption-underneath {
      display: flex;
      flex-direction: column; }
      .efs .subheader-n-images .image-caption-underneath img {
        margin: 0 20px; }
      .efs .subheader-n-images .image-caption-underneath span {
        margin: 2px 20px 0 20px;
        font-size: 80%;
        text-align: center;
        font-weight: normal; }
  .efs .subheader-image {
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    position: relative; }
    .efs .subheader-image > span:nth-child(1) {
      position: absolute;
      bottom: 22px;
      left: 0;
      font-weight: normal;
      color: red;
      font-size: 1rem; }
      .efs .subheader-image > span:nth-child(1).bsr2 {
        color: green;
        text-shadow: 0 0 1px green; }
    .efs .subheader-image div {
      flex-grow: 0;
      font-size: 1.4rem;
      margin-top: auto;
      position: relative;
      top: 4px; }
    .efs .subheader-image img {
      margin-left: 20px;
      flex-grow: 0;
      height: 60px;
      width: auto; }
  .efs .commentary {
    padding: 14px;
    background: #e0fff0;
    font-style: italic;
    border: 1px solid #9b9; }
  .efs .generic-flex-space-around {
    display: flex;
    flex-direction: row;
    justify-content: space-around; }
  .efs .generic-flex-space-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
  .efs .two-column-flex {
    display: flex; }
    .efs .two-column-flex .color-code-image img {
      width: 80px;
      height: 80px;
      margin-left: 2rem; }
    .efs .two-column-flex .color-code-text {
      margin: auto 0 auto 2rem; }
      .efs .two-column-flex .color-code-text span {
        font-weight: bold; }
  .efs .spacer0_5rem {
    display: block;
    height: 0.5rem; }
  .efs .spacer1rem {
    display: block;
    height: 1rem; }
  .efs .spacer1_5rem {
    display: block;
    height: 1.5rem; }
  .efs .spacer2rem {
    display: block;
    height: 2rem; }
  .efs .spacer2_5rem {
    display: block;
    height: 2.5rem; }
  .efs .spacer3rem {
    display: block;
    height: 3rem; }
  .efs .green-bold {
    color: #080;
    font-weight: bold; }
  .efs .red-bold {
    color: #e00;
    font-weight: bold; }
  .efs .blue-bold {
    color: #01e;
    font-weight: bold; }
  .efs .italic-bold {
    font-style: italic;
    font-weight: bold; }
  .efs .bare-ul {
    margin: 0.3rem 0 0 2%;
    padding: 0; }
  .efs .sub-box {
    display: table;
    border: 1px solid black;
    margin: 3px 0 5px 8px;
    background-color: #dfffdf;
    padding-right: 20px;
    padding-top: 1px;
    padding-bottom: 1px; }
  .efs .ul-no-top {
    margin-block-start: 0; }
  .efs .ol-less-top {
    margin-block-start: 0.3rem; }
  .efs .exception {
    color: red;
    text-shadow: 0 0 red; }
  .efs .white-text {
    color: white;
    text-shadow: none !important; }
  .efs .bigger-bold-text {
    font-size: 150%;
    font-weight: bold;
    position: relative;
    top: 10px;
    margin-left: 0.1rem;
    margin-right: 0.1rem; }
  .efs .asterisk {
    font-size: 2rem;
    position: relative;
    bottom: -0.55rem;
    line-height: 1rem; }
  .efs .subheader-rule {
    margin-top: 2rem;
    font-size: 1.4rem;
    font-weight: bold;
    position: relative; }
    .efs .subheader-rule > span:first-child {
      font-weight: normal;
      font-size: 1;
      position: absolute;
      bottom: 1.7rem;
      left: 0;
      color: red;
      font-size: 1rem; }
  .efs .rule-ref {
    font-weight: normal;
    font-size: 1;
    color: red;
    font-size: 1rem; }
  .efs .i {
    font-style: italic; }
