
.content {
    .svg-container {
        background-image: url('../../../images/efs/combat/example_sticky_attack.png');
        width: 430px;
        height: 401px;
        margin-left: 20px;
    }
    .combat {
        img {
            height: 400px;
            width: auto;
        }
    }
    .gray-box.additional-loss {
        max-width: 1000px;
        overflow: hidden;
    }
    .font14rem {
        font-size: 11.8rem;
    }
    .sticky-example {
        margin: 1rem auto;
        padding-bottom: 1rem;
        border: 1px solid black;
        background-color: #f3f3ff;
        display: block;
        font-size: 1.3rem;
        width: 960px;
        img {
            border: 1px solid black;
            height: 200px;
            width: auto;
            margin-left: 1rem;
        }
        > div:first-of-type {
            margin: 0 0 0 1rem;
            font-weight: bold;
        }

        div {
            padding-top: 0.5rem;
            margin: auto;
        }
        .text {
            width: 600px;
            margin-right: 1rem;
        }
    }
    .stalin {
        img {
            height: 200px;
            width: auto;
        }
    }
    .special-emphasis {
        display: inline-block;
        background-color: black;
        color: yellow;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        padding: 1rem 2rem;
        border: 2px dotted yellow;
        margin: 0.5rem auto;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    .centered-bordered {
        text-align: center;
        img {
            padding: 2rem 2rem 2rem 2.6rem;
            border: 1px solid black;
        }
    }
    .counter-left {
        margin-left: 30px;
        &.flush-left {
            margin-left: 0;
        }
        img{
            height: 80px;
            width: auto;
            margin: auto;
        }
        div {
            margin-left: 10px;
            .title {
                font-weight: bold;
            }
        }
    }
    .engineer-attrition {
        border: 1px solid black;
        background-color: #ddf;
        font-size: 1.5rem;
        padding: 0.7rem;
        text-align: center;
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        .title {
            color: #01e;
            font-style: italic;
            font-weight: bold;
        }
        img {
            height: 80px;
            width: auto;
        }
        .subtitle {
            font-weight: bold;
            font-size: 1.3rem;
            position: relative;
            top: -5px;
            .asterisk {
                font-size: 1.8rem;
                position: relative;
                top: 8px;
                margin: 0 1px;
            }
        }
        .eatext {
            padding-top: 4px;
          text-align: left;
          font-size: 1.3rem;
        }
    }
    .armor-attrition {
        border: 1px solid black;
        background-color: #dd9;
        font-size: 1.5rem;
        padding: 0.7rem;
        text-align: center;
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        .generic-flex-space-around.armor {
            padding-top: 9px;
           width: 50%;
           margin: auto;
           font-size: 1.2rem;
           div {
               margin: auto;
           }
           img {
               height: 90px;
               width: 90px;
           }
        }
        .title {
            color: #01e;
            font-style: italic;
            font-weight: bold;
        }
        img {
            height: 80px;
            width: auto;
        }
        .subtitle {
            font-weight: bold;
            font-size: 1.3rem;
            position: relative;
            top: -5px;
            .asterisk {
                font-size: 1.8rem;
                position: relative;
                top: 8px;
                margin: 0 1px;
            }
        }
        .aatext {
          padding: 14px 10px 0 10px;
          text-align: left;
          font-size: 1.3rem;
          .moveup {
              margin: 0;
          }
          .other-notes {
              font-size: 1.1rem;
          }
          .extra {
              padding: 0.5rem 0 0.4rem 0;
          }
        }
    }
}

/*
                <div className="engineer-attrition">
                    <div className="title">ENGINEER ATTRITION</div>
                    <div className="subtitle">Asterisk (*) Results and Engineers</div>
                    <img src={german_engineer} alt="German engineer counter"/>
                    <div>
                    If an asterisk was on the combat result, then:<br/>
                    if Engineering Effects had been declared by the attacker to get a -1 DRM, then:<br/>
                    combat result of * means - one engineer step lost.<br/>
                    combat result of 1* means - one engineer step lost (satisfies the numerical loss of 1).
                    </div>
                </div>
                */