.body,
html {
  overflow-x: hidden;
}

.block-hidden {
  display: none;
}

.index {
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    .index-title {
      font-size: 3rem;
      font-family: serif;
      padding: 2rem 1rem;
    }

    .links {
      margin: 2rem;
      a {
        text-decoration: none;
      }
      .link {
        display: flex;
        margin-top: 20px;
        img {
          box-shadow: 1px 1px 1px 1px rgba(140, 140, 140, 1), 2px 2px 1px 1px rgba(130, 130, 130, 1),
            3px 3px 1px 1px rgba(140, 140, 140, 1), 4px 4px 1px 1px rgba(110, 110, 110, 1);
        }
        .hex-logo {
          width: 118px;
          height: 105px;
          position: relative;
          top: 30px;
        }
        .counter-logo {
          width: 118px;
          height: 118px;
          position: relative;
          top: 23px;
          box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        }
        div {
          border: 1px solid black;
          display: inline-block;
          border-radius: 0.4rem;
          line-height: 4.1rem;
          margin: 3rem 1rem 2rem 2rem;
          padding: 0 2rem;
          background-image: linear-gradient(#ececfd, #b1b1c2);
          cursor: pointer;
          &:hover {
            background-image: linear-gradient(#f5f5ff, #c1c1d2);
          }
          &:active {
            background-image: linear-gradient(#acaccd, #717192);
          }
          span {
            position: relative;
            top: 1px;
            font-size: 1.5rem;
            color: black;
            text-decoration: none;
            &:active {
              color: yellow;
            }
          }
        }
      }
    }
  }
}

.bulletsWithImageToRight {
  display: flex;
  width: 700px;
  padding: 10px 15px;
  background-color: #cacaca;
  white-space: nowrap;
  border: 1px solid black;
  > div {
    margin-right: 50px;
  }
  ul {
    list-style-position: inside;
  }
  img {
    width: auto;
  }
}

a:active,
a:hover {
  outline: 0;
}

.image-by-image {
  display: flex;
  justify-content: center;
}
