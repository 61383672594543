a.bsr-link {
     font-size: 14px;
     position: relative;
     top: -5px;
     &.title {
         top: -10px;
     }
     text-decoration: none;
     border: 1px solid rgba(0,0,0,0.2);
     padding: 1px 2px;
     white-space: nowrap;
     border-radius: 3px;
     margin: 0 2px;
     font-weight: 100;
     span.bsr-link-title {
         color: blue;
         font-size: 90%;
         margin-right: 3px;
         letter-spacing: 0.02rem;
     }
     span.bsr-link-rule {
         color: #f00;
         text-shadow: 0.01rem 0.01rem #b00;
     }
 
     &:hover {
         border: 1px solid #33f;
         background-color: rgba(255,255,0,0.4);
         text-shadow: 0.01rem 0.01rem black;
     }

 }