body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.body,
html {
  overflow-x: hidden; }

.block-hidden {
  display: none; }

.index {
  overflow: hidden;
  max-width: 100%;
  max-height: 100%; }
  .index .overlay {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden; }
    .index .overlay .index-title {
      font-size: 3rem;
      font-family: serif;
      padding: 2rem 1rem; }
    .index .overlay .links {
      margin: 2rem; }
      .index .overlay .links a {
        text-decoration: none; }
      .index .overlay .links .link {
        display: flex;
        margin-top: 20px; }
        .index .overlay .links .link img {
          box-shadow: 1px 1px 1px 1px #8c8c8c, 2px 2px 1px 1px #828282, 3px 3px 1px 1px #8c8c8c, 4px 4px 1px 1px #6e6e6e; }
        .index .overlay .links .link .hex-logo {
          width: 118px;
          height: 105px;
          position: relative;
          top: 30px; }
        .index .overlay .links .link .counter-logo {
          width: 118px;
          height: 118px;
          position: relative;
          top: 23px;
          box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5); }
        .index .overlay .links .link div {
          border: 1px solid black;
          display: inline-block;
          border-radius: 0.4rem;
          line-height: 4.1rem;
          margin: 3rem 1rem 2rem 2rem;
          padding: 0 2rem;
          background-image: linear-gradient(#ececfd, #b1b1c2);
          cursor: pointer; }
          .index .overlay .links .link div:hover {
            background-image: linear-gradient(#f5f5ff, #c1c1d2); }
          .index .overlay .links .link div:active {
            background-image: linear-gradient(#acaccd, #717192); }
          .index .overlay .links .link div span {
            position: relative;
            top: 1px;
            font-size: 1.5rem;
            color: black;
            text-decoration: none; }
            .index .overlay .links .link div span:active {
              color: yellow; }

.bulletsWithImageToRight {
  display: flex;
  width: 700px;
  padding: 10px 15px;
  background-color: #cacaca;
  white-space: nowrap;
  border: 1px solid black; }
  .bulletsWithImageToRight > div {
    margin-right: 50px; }
  .bulletsWithImageToRight ul {
    list-style-position: inside; }
  .bulletsWithImageToRight img {
    width: auto; }

a:active,
a:hover {
  outline: 0; }

.image-by-image {
  display: flex;
  justify-content: center; }

@charset "UTF-8";
.efs {
  font-family: arial, helvetica, sans-serif;
  font-size: 20px;
  /*
  <div className="gray-box">
 <div className="subsubheader">COLOR CODES</div>
  <div className="two-column-flex">
      <div className="color-code-image"><img src={c1} alt="attack factor is red" /></div>
      <div className="color-code-text">
          <div><span>Attack factor is red:</span> Armored unit.</div>
          <div><span>Movement in red box:</span> Unit is motorized.
      </div>
  </div>
</div>
*/ }
  .efs .b {
    font-weight: bold; }
  .efs .content {
    margin: 0 5px 100px 0px;
    padding-left: 30px; }
    .efs .content .stand-out {
      font-size: 125%;
      font-weight: bold;
      padding: 0 0 2px 0; }
      .efs .content .stand-out.green {
        color: #088308;
        text-shadow: -1px -1px rgba(100, 255, 148, 0.5), 1px 1px rgba(0, 0, 0, 0.3); }
      .efs .content .stand-out.red {
        color: red;
        text-shadow: -1px -1px rgba(255, 155, 148, 0.5), 1px 1px rgba(0, 0, 0, 0.3); }
    .efs .content .image-caption {
      display: flex;
      width: auto;
      flex-direction: column;
      align-items: center;
      border: 1px solid #aaa;
      background-color: #e6e6e6;
      text-align: center;
      font-size: 1.3rem; }
      .efs .content .image-caption img {
        height: auto;
        width: auto; }
      .efs .content .image-caption div {
        flex-grow: 0;
        display: inline-block; }
      .efs .content .image-caption.flotilla {
        width: 644px;
        padding-bottom: 3px; }
    .efs .content .slight-bold {
      text-shadow: 0 0 0 rgba(0, 0, 0, 0.7); }
    .efs .content .italic {
      font-style: italic; }
    .efs .content .comment-boxes {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 10px; }
      .efs .content .comment-boxes > div {
        margin: 10px; }
    .efs .content .title_img_comment_box {
      margin-left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      display: inline-block;
      text-align: center;
      flex-direction: row;
      justify-content: center;
      background: #eeeeee;
      border: 1px solid black;
      padding: 4px; }
      .efs .content .title_img_comment_box .title {
        font-weight: bold;
        font-size: 1.3rem;
        text-align: center;
        padding: 5px 0 7px 0; }
      .efs .content .title_img_comment_box .image {
        text-align: center;
        width: auto; }
      .efs .content .title_img_comment_box .comment {
        padding: 5px 10px;
        font-size: 1.1rem;
        white-space: nowrap; }
    .efs .content .content-title {
      display: flex;
      font-size: 2rem;
      font-weight: bold;
      padding: 2rem 0 1rem 0; }
      .efs .content .content-title div:nth-child(1) {
        margin-right: 0.5rem; }
      .efs .content .content-title div:last-child {
        flex-grow: 1;
        position: relative;
        top: -0.4rem;
        border-bottom: 2px solid black; }
    .efs .content .clipart {
      margin: auto;
      width: auto;
      text-align: center;
      padding-bottom: 0.6rem; }
      .efs .content .clipart img {
        max-height: 400px;
        height: auto; }
    .efs .content p {
      font-size: 1.3rem;
      margin: 12px 0 15px 0; }
    .efs .content .pdiv {
      font-size: 1.3rem; }
    .efs .content .subheader-counter {
      display: flex;
      font-size: 1.4rem;
      font-weight: bold;
      margin-bottom: 0.5rem; }
      .efs .content .subheader-counter div {
        margin-top: auto;
        position: relative;
        top: 4px; }
      .efs .content .subheader-counter img {
        margin-left: 1.5rem; }
    .efs .content .subheader-normal {
      font-size: 1.3rem;
      font-weight: bold; }
    .efs .content .subheader {
      padding-top: 2rem;
      font-size: 1.5rem;
      font-weight: bold; }
    .efs .content .subheader2 {
      padding-top: 2rem;
      font-size: 1.4rem;
      font-weight: bold; }
    .efs .content .subheader1_4 {
      padding-top: 2rem;
      padding-bottom: 0.5rem;
      font-size: 1.4rem;
      font-weight: bold; }
    .efs .content .subsubheader2 {
      padding-top: 1rem;
      font-size: 2rem;
      font-weight: bold;
      text-align: center; }
    .efs .content .centered {
      text-align: center; }
    .efs .content .indent1rem {
      margin-left: 1rem; }
    .efs .content .gray-box {
      clear: all;
      background-color: #edeeed;
      display: inline-block;
      margin: 0 0 0 5rem;
      font-size: 1.3rem;
      border: 1px solid #555; }
      .efs .content .gray-box .subsubheader {
        font-weight: bold;
        position: relative;
        padding-right: 10px;
        padding-left: 10px;
        white-space: nowrap; }
      .efs .content .gray-box ul {
        list-style: none;
        position: relative;
        margin: 0; }
      .efs .content .gray-box ul li:before {
        content: "\2022";
        font-size: 110%;
        /* or whatever */
        padding-right: 15px; }
    .efs .content .indented-box {
      border: 1px solid black;
      display: inline-block;
      margin-left: 20px;
      padding: 6px 10px;
      font-size: 20px; }
      .efs .content .indented-box.background-green {
        background-color: #e2ffe2;
        text-shadow: 0 0 rgba(0, 255, 0, 0.9); }
      .efs .content .indented-box.background-blue {
        background-color: #c8d8e6;
        text-shadow: 0 0 rgba(55, 55, 155, 0.9); }
      .efs .content .indented-box.background-red {
        background-color: #e8d8d6;
        text-shadow: 0 0 rgba(155, 55, 55, 0.9); }
      .efs .content .indented-box.background-beige {
        background-color: #efefe0;
        text-shadow: 0 0 rgba(110, 110, 55, 0.9); }
      .efs .content .indented-box.background-yellow {
        background-color: #ffffd0;
        text-shadow: 0 0 rgba(127, 127, 0, 0.9); }
      .efs .content .indented-box.background-pink {
        background-color: #ffdddd;
        text-shadow: 0 0 rgba(127, 127, 0, 0.9); }
      .efs .content .indented-box .indented-box-title {
        text-shadow: 0.01rem 0.01rem black;
        margin-right: 5px; }
      .efs .content .indented-box ul {
        margin: 5px 5px 2px 40px;
        padding: 0; }
      .efs .content .indented-box ol.spaced-ol {
        position: relative;
        top: -14px;
        left: -35px; }
        .efs .content .indented-box ol.spaced-ol li {
          margin: 5px 0; }
        .efs .content .indented-box ol.spaced-ol li:first-child {
          margin-top: 0px; }
    .efs .content .span-note {
      color: green; }
    .efs .content .indented-box-with-image-to-left {
      border: 1px solid black;
      display: inline-block;
      margin-left: 50px;
      padding: 6px 10px;
      font-size: 20px; }
      .efs .content .indented-box-with-image-to-left > div:first-child {
        font-weight: bold;
        margin-right: 5px; }
      .efs .content .indented-box-with-image-to-left img {
        height: auto; }
      .efs .content .indented-box-with-image-to-left ul {
        margin: 5px 5px 2px 40px;
        padding: 0; }
    .efs .content .box-with-image-to-left {
      border: 1px solid black;
      display: inline-block;
      padding: 6px 10px;
      font-size: 20px; }
      .efs .content .box-with-image-to-left > div:first-child {
        font-weight: bold;
        margin-right: 5px; }
      .efs .content .box-with-image-to-left img {
        height: auto; }
      .efs .content .box-with-image-to-left ul {
        margin: 5px 5px 2px 40px;
        padding: 0; }
  .efs .image-text {
    display: flex;
    margin-left: 20px;
    padding: 10px 5px 10px 5px;
    background-color: #ffdddd; }
    .efs .image-text ul {
      margin: 0; }
    .efs .image-text img {
      align-self: center;
      margin-left: 10px; }
    .efs .image-text > div {
      margin-left: 20px; }
    .efs .image-text.background-pink {
      border: 1px solid #eedddd;
      background-color: #ffdddd; }
    .efs .image-text.background-grayblue {
      border: 1px solid #ccccdd;
      background-color: #ddddee; }
  .efs .colored-box {
    margin-left: 20px;
    padding: 10px 5px 10px 0px;
    background-color: #ffdddd; }
    .efs .colored-box ul {
      margin: 5px 5px 0px 20px; }
      .efs .colored-box ul li {
        margin-top: 3px; }
    .efs .colored-box img {
      align-self: center;
      margin-left: 10px; }
    .efs .colored-box div.primary-heading {
      margin-left: 15px;
      text-shadow: 0 0 rgba(0, 0, 0, 0.4);
      letter-spacing: 0.01rem; }
    .efs .colored-box div.secondary-heading {
      margin-left: 37px;
      margin-top: 8px; }
    .efs .colored-box div.postscript {
      margin: 15px 5px 5px 15px; }
  .efs .small-counters-left {
    padding: 0 0 0 5px;
    border: 1px solid #cdd;
    background-color: #dee8e8; }
    .efs .small-counters-left img {
      position: relative;
      top: 3px; }
    .efs .small-counters-left span {
      position: relative;
      top: -29px;
      padding: 0 0 0 10px; }
  .efs .small-counters-left2 {
    display: flex;
    border: 1px solid #cdd;
    background-color: #E1E6E6;
    padding-right: 5px;
    max-width: 846px; }
    .efs .small-counters-left2 div:first-child {
      margin: 10px 10px 3px 10px; }
    .efs .small-counters-left2 div:nth-child(2) {
      margin: auto; }
  .efs .rules-note {
    display: inline-block;
    margin-top: 2px;
    border-radius: 16px;
    border: 1px solid #cfdfdf;
    padding: 6px 10px 5px 10px;
    background-color: #d5e9f5;
    color: #333;
    text-shadow: 0 0 rgba(0, 0, 100, 0.4);
    font-size: 96%;
    box-shadow: 2px 4px 8px 2px rgba(0, 0, 60, 0.2); }
  .efs .rules-clarification {
    display: inline-block;
    margin-top: 2px;
    border-radius: 16px;
    border: 1px solid #d3f0d5;
    padding: 6px 10px 5px 10px;
    background-color: #e5ffe0;
    color: #333;
    text-shadow: 0 0 rgba(0, 110, 0, 0.5);
    font-size: 96%;
    box-shadow: 2px 4px 8px 2px rgba(0, 60, 0, 0.2); }
  .efs .rule-ref {
    color: red;
    font-size: 1rem; }
  .efs .normal-text-n-images {
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin: 12px 0; }
    .efs .normal-text-n-images.bold {
      font-weight: bold; }
    .efs .normal-text-n-images div {
      align-self: flex-end; }
    .efs .normal-text-n-images img {
      align-self: center;
      height: auto; }
  .efs .subheader-n-images {
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin: 12px 0; }
    .efs .subheader-n-images div {
      align-self: flex-end;
      font-size: 1.4rem; }
    .efs .subheader-n-images img {
      align-self: flex-end;
      height: auto;
      margin-left: 10px; }
    .efs .subheader-n-images .image-caption-underneath {
      display: flex;
      flex-direction: column; }
      .efs .subheader-n-images .image-caption-underneath img {
        margin: 0 20px; }
      .efs .subheader-n-images .image-caption-underneath span {
        margin: 2px 20px 0 20px;
        font-size: 80%;
        text-align: center;
        font-weight: normal; }
  .efs .subheader-image {
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    position: relative; }
    .efs .subheader-image > span:nth-child(1) {
      position: absolute;
      bottom: 22px;
      left: 0;
      font-weight: normal;
      color: red;
      font-size: 1rem; }
      .efs .subheader-image > span:nth-child(1).bsr2 {
        color: green;
        text-shadow: 0 0 1px green; }
    .efs .subheader-image div {
      flex-grow: 0;
      font-size: 1.4rem;
      margin-top: auto;
      position: relative;
      top: 4px; }
    .efs .subheader-image img {
      margin-left: 20px;
      flex-grow: 0;
      height: 60px;
      width: auto; }
  .efs .commentary {
    padding: 14px;
    background: #e0fff0;
    font-style: italic;
    border: 1px solid #9b9; }
  .efs .generic-flex-space-around {
    display: flex;
    flex-direction: row;
    justify-content: space-around; }
  .efs .generic-flex-space-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
  .efs .two-column-flex {
    display: flex; }
    .efs .two-column-flex .color-code-image img {
      width: 80px;
      height: 80px;
      margin-left: 2rem; }
    .efs .two-column-flex .color-code-text {
      margin: auto 0 auto 2rem; }
      .efs .two-column-flex .color-code-text span {
        font-weight: bold; }
  .efs .spacer0_5rem {
    display: block;
    height: 0.5rem; }
  .efs .spacer1rem {
    display: block;
    height: 1rem; }
  .efs .spacer1_5rem {
    display: block;
    height: 1.5rem; }
  .efs .spacer2rem {
    display: block;
    height: 2rem; }
  .efs .spacer2_5rem {
    display: block;
    height: 2.5rem; }
  .efs .spacer3rem {
    display: block;
    height: 3rem; }
  .efs .green-bold {
    color: #080;
    font-weight: bold; }
  .efs .red-bold {
    color: #e00;
    font-weight: bold; }
  .efs .blue-bold {
    color: #01e;
    font-weight: bold; }
  .efs .italic-bold {
    font-style: italic;
    font-weight: bold; }
  .efs .bare-ul {
    margin: 0.3rem 0 0 2%;
    padding: 0; }
  .efs .sub-box {
    display: table;
    border: 1px solid black;
    margin: 3px 0 5px 8px;
    background-color: #dfffdf;
    padding-right: 20px;
    padding-top: 1px;
    padding-bottom: 1px; }
  .efs .ul-no-top {
    -webkit-margin-before: 0;
            margin-block-start: 0; }
  .efs .ol-less-top {
    -webkit-margin-before: 0.3rem;
            margin-block-start: 0.3rem; }
  .efs .exception {
    color: red;
    text-shadow: 0 0 red; }
  .efs .white-text {
    color: white;
    text-shadow: none !important; }
  .efs .bigger-bold-text {
    font-size: 150%;
    font-weight: bold;
    position: relative;
    top: 10px;
    margin-left: 0.1rem;
    margin-right: 0.1rem; }
  .efs .asterisk {
    font-size: 2rem;
    position: relative;
    bottom: -0.55rem;
    line-height: 1rem; }
  .efs .subheader-rule {
    margin-top: 2rem;
    font-size: 1.4rem;
    font-weight: bold;
    position: relative; }
    .efs .subheader-rule > span:first-child {
      font-weight: normal;
      font-size: 1;
      position: absolute;
      bottom: 1.7rem;
      left: 0;
      color: red;
      font-size: 1rem; }
  .efs .rule-ref {
    font-weight: normal;
    font-size: 1;
    color: red;
    font-size: 1rem; }
  .efs .i {
    font-style: italic; }

@charset "UTF-8";
.efs-home {
  position: relative;
  text-align: center; }
  .efs-home .new-rules {
    color: blue;
    text-decoration: none; }
    .efs-home .new-rules:hover {
      color: red; }
  .efs-home .new-rules-notice {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px; }
    .efs-home .new-rules-notice .first-para {
      font-size: 110%;
      padding: 15px 0 5px; }
    .efs-home .new-rules-notice a.product-link {
      text-decoration: none; }
      .efs-home .new-rules-notice a.product-link .product-style {
        margin: 10px;
        border: 5px solid black;
        padding: 10px 25px;
        background: linear-gradient(90deg, #7d1d27 0%, #8d2d36 100%); }
        .efs-home .new-rules-notice a.product-link .product-style > div:first-child {
          color: #f3d518;
          font-size: 200%;
          font-weight: bold;
          text-align: center;
          text-shadow: 1px 1px rgba(243, 213, 24, 0.5) -1px -1px rgba(243, 213, 24, 0.5); }
        .efs-home .new-rules-notice a.product-link .product-style > div:nth-child(2) {
          color: white;
          font-size: 103%;
          font-weight: bold;
          letter-spacing: 0.01em; }
        .efs-home .new-rules-notice a.product-link .product-style > div:nth-child(3) {
          color: white;
          font-size: 90%;
          margin-top: 10px;
          font-style: italic;
          text-align: center;
          color: #aff;
          letter-spacing: 0.01em; }
    .efs-home .new-rules-notice div.bsr-link {
      font-size: 140%; }
  .efs-home .update-notice {
    padding: 10px;
    margin: 10px 0 5px 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 120%; }
  .efs-home .notice-box {
    width: 550px;
    margin: 0 auto;
    border: 1px solid black;
    margin-top: 2rem;
    background-color: #f5fbff;
    box-sizing: border-box; }
    .efs-home .notice-box .notice-title {
      font-size: 1.3rem;
      background: black;
      color: white;
      font-weight: bold;
      padding: 1px; }
    .efs-home .notice-box .content-notice {
      padding: 10px;
      font-size: 1.2rem; }
    .efs-home .notice-box .linky {
      position: relative;
      top: -0.35rem; }
      .efs-home .notice-box .linky a {
        color: blue;
        text-decoration: none;
        font-size: 1.5rem; }
        .efs-home .notice-box .linky a:hover {
          color: red; }
    .efs-home .notice-box .please {
      font-size: 0.9rem;
      padding-bottom: 0.5rem; }
  .efs-home .info {
    width: 90%;
    margin: auto;
    text-align: left;
    font-size: 1.25rem; }
    .efs-home .info .tm {
      font-weight: bold;
      color: #900; }
  .efs-home img {
    width: 80%;
    max-width: 1200px;
    height: auto; }
  .efs-home .contents {
    margin-top: 1.8rem;
    background: #fae9b2;
    margin-bottom: 3.5rem;
    display: flex; }
    .efs-home .contents .contents-list {
      padding-top: 0.8rem;
      padding-bottom: 0.1rem;
      margin-left: 20%;
      width: 470px;
      text-align: left; }
      .efs-home .contents .contents-list ul {
        list-style: none; }
      .efs-home .contents .contents-list ul li:before {
        content: "\2022";
        font-size: 170%;
        /* or whatever */
        padding-right: 5px; }
      .efs-home .contents .contents-list a {
        color: #24e;
        font-size: 1.7rem;
        font-weight: bold;
        text-decoration: none; }
        .efs-home .contents .contents-list a:hover {
          color: red; }
      .efs-home .contents .contents-list .contents-title {
        font-size: 2.8rem;
        font-weight: bold; }
    .efs-home .contents .images {
      padding-top: 0.93%;
      margin-left: 5%; }
      @media only screen and (max-width: 1100px) {
        .efs-home .contents .images {
          display: none; } }
      .efs-home .contents .images img {
        width: auto;
        height: 380px; }

a.bsr-link {
  font-size: 14px;
  position: relative;
  top: -5px;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1px 2px;
  white-space: nowrap;
  border-radius: 3px;
  margin: 0 2px;
  font-weight: 100; }
  a.bsr-link.title {
    top: -10px; }
  a.bsr-link span.bsr-link-title {
    color: blue;
    font-size: 90%;
    margin-right: 3px;
    letter-spacing: 0.02rem; }
  a.bsr-link span.bsr-link-rule {
    color: #f00;
    text-shadow: 0.01rem 0.01rem #b00; }
  a.bsr-link:hover {
    border: 1px solid #33f;
    background-color: rgba(255, 255, 0, 0.4);
    text-shadow: 0.01rem 0.01rem black; }


.unit-size {
  margin-left: 5rem;
  border: 1px solid black;
  border-collapse: collapse;
  font-size: 1.3rem; }
  .unit-size td {
    border: 1px solid black; }
    .unit-size td:nth-child(1), .unit-size td:nth-child(2) {
      text-align: center;
      font-weight: bold;
      padding: 0 1rem; }
    .unit-size td:nth-child(3) {
      padding: 0.1rem 1rem 0.1rem 1rem; }

.unit-type {
  font-size: 1.4rem; }
  .unit-type img {
    width: auto;
    height: 25px; }
  .unit-type td:nth-child(1), .unit-type td:nth-child(3), .unit-type td:nth-child(5), .unit-type td:nth-child(7) {
    padding-top: 5px; }
  .unit-type td:nth-child(2), .unit-type td:nth-child(4), .unit-type td:nth-child(6), .unit-type td:nth-child(8) {
    padding-right: 35px; }

.note {
  font-size: 1.3rem; }

.red {
  color: red;
  margin-left: 2px;
  font-weight: bold; }

.content .chart-container {
  width: 810px;
  margin: auto; }
  .content .chart-container .flex3column {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.05rem; }
    .content .chart-container .flex3column div.first {
      width: 50px;
      font-size: 2rem;
      line-height: 1.6rem;
      flex-grow: 0;
      color: black;
      box-sizing: border-box;
      margin: 0 10px 0 10px;
      padding: 20px 0 20px 0; }
    .content .chart-container .flex3column div.second {
      width: 340px;
      flex-grow: 0;
      color: white;
      background-color: #4b5a61;
      border: 1px solid black;
      box-sizing: border-box;
      margin: 0 15px 0 10px;
      padding: 21px 0 19px 0;
      text-shadow: -1px -1px 1px rgba(225, 255, 255, 0.2), 1px 1px 1px rgba(0, 0, 0, 0.1), 2px 2px 1px rgba(0, 0, 0, 0.1); }
      .content .chart-container .flex3column div.second:empty {
        background-color: white;
        border: 0; }
    .content .chart-container .flex3column div.third {
      width: 340px;
      flex-grow: 0;
      color: white;
      background-color: #b85a0f;
      border: 1px solid black;
      box-sizing: border-box;
      margin: 0 10px 0 17px;
      padding: 21px 0 19px 0;
      text-shadow: -1px -1px 1px rgba(225, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.1), 2px 2px 1px rgba(0, 0, 0, 0.05); }
      .content .chart-container .flex3column div.third:empty {
        background-color: white;
        border: none; }
    .content .chart-container .flex3column div.both {
      width: 680px;
      flex-grow: 0;
      color: white;
      background-color: #888;
      border: 1px solid black;
      box-sizing: border-box;
      margin: 0 10px 0 17px;
      padding: 21px 0 19px 0;
      text-shadow: -1px -1px 1px rgba(225, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.1), 2px 2px 1px rgba(0, 0, 0, 0.05); }
      .content .chart-container .flex3column div.both:empty {
        display: none; }
    .content .chart-container .flex3column div.line {
      width: 800px;
      height: 2px;
      background-color: #ccc;
      margin: 10px 0 10px 0; }
    .content .chart-container .flex3column div.ind {
      color: black;
      background-color: transparent;
      font-size: 1.5rem;
      padding: 0;
      position: relative;
      top: 10px;
      border: none;
      text-shadow: none; }

.content .rations {
  margin: 1vw auto;
  width: 80%; }
  .content .rations img {
    height: 20vw;
    min-height: 200px;
    max-height: 500px;
    width: auto; }

.content .example {
  border: 1px solid black;
  padding: 1rem 1vw;
  margin-left: 2%; }
  .content .example.example1 {
    width: 1100px; }
    @media only screen and (max-width: 1300px) {
      .content .example.example1 {
        width: 900px; } }
  .content .example.example2 {
    width: 1300px; }
    @media only screen and (max-width: 1300px) {
      .content .example.example2 {
        width: 900px; } }
  .content .example.example3 {
    width: 1300px; }
    @media only screen and (max-width: 1300px) {
      .content .example.example3 {
        width: 900px; } }
  .content .example .example3 {
    text-align: center;
    position: relative;
    left: -1rem; }
    .content .example .example3 img {
      max-width: 1000px;
      min-width: 800px;
      width: 90%;
      height: auto; }
  .content .example .example-name {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    padding-left: 1.3rem;
    padding-bottom: 0.4rem; }
  .content .example img {
    width: 400px;
    height: auto; }
  .content .example div:nth-child(2) {
    margin: auto 0 auto 2rem; }
    .content .example div:nth-child(2).row-text {
      margin: auto 0 auto 0; }
    @media only screen and (max-width: 1300px) {
      .content .example div:nth-child(2) .minor {
        font-size: 1rem; } }

.content .header-1-image span {
  position: absolute;
  bottom: 22px;
  left: 0;
  font-weight: normal;
  color: red;
  font-size: 1rem; }

.content .header-1-image .header-1-image-title {
  margin-top: auto;
  position: relative;
  top: 4px;
  font-size: 1.3rem; }

.content .header-1-image img {
  height: 250px;
  width: auto;
  margin-left: 2rem; }

.content .header-1-image.infiltration img {
  border: 1px solid black; }

.content .header-2-image > span:nth-child(1) {
  position: absolute;
  bottom: 22px;
  left: 0;
  font-weight: normal;
  color: red;
  font-size: 1rem; }

.content .header-2-image div {
  margin-top: auto;
  position: relative;
  top: 4px;
  font-size: 1.3rem; }

.content .header-2-image img {
  height: 200px;
  width: auto;
  margin-left: 2rem; }

.content .header-2-image img:first-of-type {
  margin-top: auto;
  margin-left: 1.5rem;
  height: 70px;
  width: auto; }

.content .road-signs img {
  height: 30vw;
  max-height: 500px;
  min-height: 300px;
  width: auto; }

.content .unit-examples {
  width: 98%;
  margin: auto;
  padding-top: 1rem; }
  .content .unit-examples .unit-example {
    text-align: center;
    margin: 1.5rem 0.1rem;
    font-size: 1rem; }
    .content .unit-examples .unit-example img {
      height: 90px;
      width: auto; }
      @media only screen and (min-width: 1900px) {
        .content .unit-examples .unit-example img {
          height: 100px; } }
    .content .unit-examples .unit-example :nth-child(2) {
      font-weight: bold; }
      @media only screen and (min-width: 2000px) {
        .content .unit-examples .unit-example :nth-child(2) {
          font-size: 1.3rem; } }
    @media only screen and (min-width: 2000px) {
      .content .unit-examples .unit-example :nth-child(3) {
        font-size: 1.5rem; } }

.content .movement-phases {
  padding-top: 1.8rem;
  font-size: 1.3rem;
  display: inline-block;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: center; }
  .content .movement-phases .phases {
    padding-top: 0.3rem;
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    flex-direction: row; }
    .content .movement-phases .phases img {
      width: 31px;
      height: 26px;
      margin: 1px 8px 0 10px; }
  .content .movement-phases .axis {
    padding: 0.5rem 1rem 0.4rem 0.7rem;
    background-color: #B8BFC3;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.3); }
  .content .movement-phases .soviet {
    padding: 0.5rem 1rem 0.4rem 0.7rem;
    background-color: #FC9F62;
    border-top: 2px dotted rgba(0, 0, 0, 0.3); }

.content .svg-container {
  background-image: url(/static/media/example_sticky_attack.2057deb7.png);
  width: 430px;
  height: 401px;
  margin-left: 20px; }

.content .combat img {
  height: 400px;
  width: auto; }

.content .gray-box.additional-loss {
  max-width: 1000px;
  overflow: hidden; }

.content .font14rem {
  font-size: 11.8rem; }

.content .sticky-example {
  margin: 1rem auto;
  padding-bottom: 1rem;
  border: 1px solid black;
  background-color: #f3f3ff;
  display: block;
  font-size: 1.3rem;
  width: 960px; }
  .content .sticky-example img {
    border: 1px solid black;
    height: 200px;
    width: auto;
    margin-left: 1rem; }
  .content .sticky-example > div:first-of-type {
    margin: 0 0 0 1rem;
    font-weight: bold; }
  .content .sticky-example div {
    padding-top: 0.5rem;
    margin: auto; }
  .content .sticky-example .text {
    width: 600px;
    margin-right: 1rem; }

.content .stalin img {
  height: 200px;
  width: auto; }

.content .special-emphasis {
  display: inline-block;
  background-color: black;
  color: yellow;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem 2rem;
  border: 2px dotted yellow;
  margin: 0.5rem auto;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.content .centered-bordered {
  text-align: center; }
  .content .centered-bordered img {
    padding: 2rem 2rem 2rem 2.6rem;
    border: 1px solid black; }

.content .counter-left {
  margin-left: 30px; }
  .content .counter-left.flush-left {
    margin-left: 0; }
  .content .counter-left img {
    height: 80px;
    width: auto;
    margin: auto; }
  .content .counter-left div {
    margin-left: 10px; }
    .content .counter-left div .title {
      font-weight: bold; }

.content .engineer-attrition {
  border: 1px solid black;
  background-color: #ddf;
  font-size: 1.5rem;
  padding: 0.7rem;
  text-align: center;
  display: inline-block;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .content .engineer-attrition .title {
    color: #01e;
    font-style: italic;
    font-weight: bold; }
  .content .engineer-attrition img {
    height: 80px;
    width: auto; }
  .content .engineer-attrition .subtitle {
    font-weight: bold;
    font-size: 1.3rem;
    position: relative;
    top: -5px; }
    .content .engineer-attrition .subtitle .asterisk {
      font-size: 1.8rem;
      position: relative;
      top: 8px;
      margin: 0 1px; }
  .content .engineer-attrition .eatext {
    padding-top: 4px;
    text-align: left;
    font-size: 1.3rem; }

.content .armor-attrition {
  border: 1px solid black;
  background-color: #dd9;
  font-size: 1.5rem;
  padding: 0.7rem;
  text-align: center;
  display: inline-block;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .content .armor-attrition .generic-flex-space-around.armor {
    padding-top: 9px;
    width: 50%;
    margin: auto;
    font-size: 1.2rem; }
    .content .armor-attrition .generic-flex-space-around.armor div {
      margin: auto; }
    .content .armor-attrition .generic-flex-space-around.armor img {
      height: 90px;
      width: 90px; }
  .content .armor-attrition .title {
    color: #01e;
    font-style: italic;
    font-weight: bold; }
  .content .armor-attrition img {
    height: 80px;
    width: auto; }
  .content .armor-attrition .subtitle {
    font-weight: bold;
    font-size: 1.3rem;
    position: relative;
    top: -5px; }
    .content .armor-attrition .subtitle .asterisk {
      font-size: 1.8rem;
      position: relative;
      top: 8px;
      margin: 0 1px; }
  .content .armor-attrition .aatext {
    padding: 14px 10px 0 10px;
    text-align: left;
    font-size: 1.3rem; }
    .content .armor-attrition .aatext .moveup {
      margin: 0; }
    .content .armor-attrition .aatext .other-notes {
      font-size: 1.1rem; }
    .content .armor-attrition .aatext .extra {
      padding: 0.5rem 0 0.4rem 0; }

/*
                <div className="engineer-attrition">
                    <div className="title">ENGINEER ATTRITION</div>
                    <div className="subtitle">Asterisk (*) Results and Engineers</div>
                    <img src={german_engineer} alt="German engineer counter"/>
                    <div>
                    If an asterisk was on the combat result, then:<br/>
                    if Engineering Effects had been declared by the attacker to get a -1 DRM, then:<br/>
                    combat result of * means - one engineer step lost.<br/>
                    combat result of 1* means - one engineer step lost (satisfies the numerical loss of 1).
                    </div>
                </div>
                */

.content .unit-info {
  position: relative;
  margin: auto;
  width: 800px;
  height: 340px;
  border: 1px solid black;
  background: linear-gradient(to bottom, #cfefff 31%, #eaf9ff 100%); }
  .content .unit-info img {
    position: absolute;
    top: 80px;
    left: 320px;
    width: 140px; }
  .content .unit-info .title {
    position: absolute;
    top: 10px;
    left: 160px;
    font-size: 2rem;
    font-weight: bold; }
  .content .unit-info .model {
    font-size: 1.5rem;
    position: absolute;
    left: 62px;
    top: 73px;
    text-align: center; }
  .content .unit-info .rating {
    font-size: 1.5rem;
    position: absolute;
    left: 55px;
    top: 203px;
    text-align: center; }
  .content .unit-info .type {
    font-size: 1.5rem;
    position: absolute;
    left: 575px;
    top: 91px;
    text-align: center; }
  .content .unit-info .close {
    font-size: 1.5rem;
    position: absolute;
    left: 296px;
    top: 285px;
    text-align: center; }
  .content .unit-info .interdiction {
    font-size: 1.5rem;
    position: absolute;
    left: 541px;
    top: 225px;
    text-align: center; }

.content .air-combat-determination-container {
  position: relative;
  width: 1190px;
  margin: 2rem auto;
  height: 520px;
  overflow: hidden;
  background: linear-gradient(to bottom, #cfefff 31%, #eaf9ff 100%); }
  .content .air-combat-determination-container .air-combat-determination {
    border: 1px solid black;
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
    text-align: center;
    position: absolute; }
    .content .air-combat-determination-container .air-combat-determination .title {
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      position: relative;
      top: 0.25rem; }
    .content .air-combat-determination-container .air-combat-determination .roll-on {
      border: 1px solid #999;
      display: inline-block;
      padding: 0.3rem 0.6rem;
      margin-top: 2rem;
      margin-bottom: 4rem;
      background-color: white; }
    .content .air-combat-determination-container .air-combat-determination .row {
      display: flex;
      margin-bottom: 3rem; }
      .content .air-combat-determination-container .air-combat-determination .row .air-gray-box {
        padding: 0.2rem 0.4rem;
        border: 1px solid #999;
        background-color: #e4e4f4;
        width: 210px;
        margin: 0 20px; }
      .content .air-combat-determination-container .air-combat-determination .row .air-white-box {
        padding: 0.2rem 0.4rem;
        border: 1px solid #999;
        background-color: white;
        width: 210px;
        margin: 0 20px; }
      .content .air-combat-determination-container .air-combat-determination .row .air-orange-box {
        padding: 0.2rem 0.4rem;
        border: 1px solid #999;
        background-color: #fcd9c2;
        width: 210px;
        margin: 0 20px; }
      .content .air-combat-determination-container .air-combat-determination .row .air-empty-box {
        padding: 0.2rem 0.4rem;
        width: 210px;
        margin: 0 20px; }
    .content .air-combat-determination-container .air-combat-determination .end-box {
      padding: 0.7rem 0;
      margin: 4rem auto 1rem auto;
      width: 700px;
      border: 1px solid #999;
      background-color: white; }
    .content .air-combat-determination-container .air-combat-determination .end-box-fr {
      padding: 0.7rem 0;
      margin: 4rem auto 1rem auto;
      width: 1100px;
      border: 1px solid #999;
      background-color: white; }

.content .air-explanations {
  display: flex; }
  .content .air-explanations > :nth-child(1) {
    font-weight: bold;
    white-space: nowrap; }
  .content .air-explanations > :nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; }
    .content .air-explanations > :nth-child(2) ol {
      margin-top: 0; }
    .content .air-explanations > :nth-child(2) div {
      flex: 50% 1; }
    .content .air-explanations > :nth-child(2) div:nth-child(even) {
      border: 1px solid transparent;
      margin-right: auto;
      width: 50%; }

.content .air-combat-example {
  display: flex;
  border: 1px solid black;
  width: 1000px;
  padding: 1rem;
  background-color: #edeeed; }
  .content .air-combat-example div:nth-child(2) {
    border: 1px solid #e0e1ee;
    padding: 1rem;
    background-color: white;
    box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, 0.1), inset 2px 2px 2px 2px rgba(0, 0, 0, 0.05); }

.content .subheader-image.antiair img {
  height: 300px;
  width: auto; }

.content .subheader-normal.no-top {
  padding-top: 0; }

.content .ul-no-top-bottom {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 3rem;
          margin-inline-start: 3rem; }

.content .generic-flex-space-start {
  margin-top: 1rem;
  border: 1px solid black;
  padding: 1rem;
  background-color: #edeeed; }
  .content .generic-flex-space-start.soviet {
    background-color: #FCD9C2; }
  .content .generic-flex-space-start.romanian {
    background-color: #eaf9e2; }
  .content .generic-flex-space-start > :nth-child(3) {
    flex: 0 1 auto;
    height: calc(8vw + 100px);
    max-height: 300px; }
  .content .generic-flex-space-start.heinkel > :nth-child(3) {
    flex: 0 1 auto;
    margin-left: auto;
    height: calc(8vw + 140px);
    width: auto;
    max-height: 320px; }
  .content .generic-flex-space-start.dornier > :nth-child(3) {
    flex: 0 1 auto;
    margin-left: auto;
    height: calc(8vw + 50px);
    width: auto;
    max-height: 260px; }
  .content .generic-flex-space-start.messerschmitt > :nth-child(3) {
    flex: 0 1 auto;
    margin-left: auto;
    height: calc(8vw + 50px);
    width: auto;
    max-height: 260px; }
  .content .generic-flex-space-start.messerschmitt109 > :nth-child(3) {
    flex: 0 1 auto;
    margin-left: auto;
    height: calc(8vw + 170px);
    width: auto;
    max-height: 360px; }
  .content .generic-flex-space-start.il2 > :nth-child(3) {
    flex: 0 1 auto;
    margin-left: auto;
    height: calc(8vw + 160px);
    width: auto;
    max-height: 340px; }
  .content .generic-flex-space-start.sb2 > :nth-child(3) {
    flex: 0 1 auto;
    margin-left: auto;
    height: calc(8vw + 50px);
    width: auto;
    max-height: 240px; }
  .content .generic-flex-space-start > div:nth-child(2) {
    margin-left: 1rem;
    margin-right: 1rem;
    max-width: 70rem; }
  .content .generic-flex-space-start div:nth-child(1) {
    font-weight: bold;
    padding: 0 0 0.5rem 0; }
  .content .generic-flex-space-start .aircraft-counter {
    height: 80px;
    width: auto; }

/*
<div className="air-combat-example">
<div>
    <div>
        It is the Soviet turn, and the Soviet player has flown a CAS mission to a Declared Combat hex. 
    </div><div>
        The Soviet player has sent two bombers and a fighter on this mission. 
    </div><div>
        The German player decides to send a CAS mission also to the same Declared Combat hex. 
</div><div>
        The German player has sent two fighters.
    </div>
</div>
<div>
    <img src={aircombat_ex1} alt="air combat example 1"/>
</div>
</div>*/

.content .top-image {
  text-align: center; }
  .content .top-image div {
    padding: 0.3rem 1.7rem;
    box-sizing: border-box;
    position: relative;
    top: -0.3rem;
    background-color: #eee;
    font-size: 1.2rem;
    width: 845px;
    text-align: left;
    margin: auto;
    border: 1px solid gray; }

.content .hq-info-container {
  background: linear-gradient(to bottom, #f1dbc7 31%, #eeddce 100%);
  width: 840px;
  height: 255px;
  padding: 0px 10px 30px 0;
  margin: auto;
  border: 1px solid black;
  overflow: hidden;
  position: relative; }
  .content .hq-info-container .title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding: 10px 0 60px 0; }
  .content .hq-info-container .operational-side {
    position: absolute;
    font-size: 1.5rem;
    top: 63px;
    left: 219px; }
  .content .hq-info-container .non-operational-side {
    position: absolute;
    font-size: 1.5rem;
    top: 63px;
    left: 572px; }
  .content .hq-info-container .command-value {
    position: absolute;
    font-size: 1.5rem;
    top: 142px;
    left: 55px;
    text-align: center; }
    .content .hq-info-container .command-value.fr {
      font-size: 1.2rem; }
  .content .hq-info-container .recovery-value {
    position: absolute;
    font-size: 1.5rem;
    top: 142px;
    left: 432px;
    text-align: center; }
    .content .hq-info-container .recovery-value.fr {
      left: 410px;
      font-size: 1.2rem; }
  .content .hq-info-container .hq-info {
    position: absolute;
    display: flex;
    justify-content: center; }
    .content .hq-info-container .hq-info img {
      margin: 1px 0 4px 230px;
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3), 2px 2px 2px 2px rgba(0, 0, 0, 0.3), 3px 3px 2px 2px rgba(0, 0, 0, 0.3), 4px 4px 3px 3px rgba(0, 0, 0, 0.2);
      border-top: 1px solid #f6b072;
      border-left: 1px solid #f6b072;
      border-radius: 10px;
      overflow: hidden; }

.content .special1 {
  position: relative;
  top: -0.6rem; }

.content .armored {
  margin: auto;
  height: auto;
  width: 824px;
  height: 445px;
  border: 2px solid #ccc;
  overflow: hidden;
  font-size: 1.3rem;
  background-color: #eee;
  box-sizing: border-box; }
  .content .armored img {
    text-align: center;
    display: block;
    padding-bottom: 10px; }

.content .gray-box img {
  margin-left: 2rem; }

.content .ship img, .content .base img {
  height: 60px;
  width: auto; }

.content .naval-info-container {
  background: linear-gradient(to bottom, #f1dbc7 31%, #eeddce 100%);
  position: relative;
  position: block;
  width: 767px;
  height: 300px;
  margin: auto; }
  .content .naval-info-container .floater {
    position: absolute;
    text-align: center; }
    .content .naval-info-container .floater.range {
      font-size: 1.2rem;
      position: absolute;
      left: 59px;
      top: 86px; }
    .content .naval-info-container .floater.arty {
      font-size: 1.2rem;
      position: absolute;
      left: 40px;
      top: 130px; }
    .content .naval-info-container .floater.protection {
      font-size: 1.2rem;
      position: absolute;
      left: 80px;
      top: 230px; }
    .content .naval-info-container .floater.transport {
      font-size: 1.2rem;
      position: absolute;
      left: 230px;
      top: 230px; }
    .content .naval-info-container .floater.movement {
      font-size: 1.2rem;
      position: absolute;
      left: 342px;
      top: 146px; }
    .content .naval-info-container .floater.class {
      font-size: 1.2rem;
      position: absolute;
      left: 362px;
      top: 100px; }
    .content .naval-info-container .floater.name {
      font-size: 1.2rem;
      position: absolute;
      left: 358px;
      top: 70px; }
    .content .naval-info-container .floater.recovery {
      font-size: 1.2rem;
      position: absolute;
      left: 648px;
      top: 111px; }
    .content .naval-info-container .floater.sailed {
      font-size: 1.2rem;
      position: absolute;
      left: 532px;
      top: 241px; }
  .content .naval-info-container .title {
    font-size: 1.5rem;
    position: absolute;
    font-weight: bold;
    text-align: center;
    position: relative;
    top: 9px; }
  .content .naval-info-container .ships {
    display: flex;
    justify-content: space-around;
    margin: 20px 130px 20px 140px;
    text-align: center;
    font-size: 1.2rem; }
    .content .naval-info-container .ships > :nth-child(2) {
      margin-left: 120px; }
    .content .naval-info-container .ships div {
      padding: 0 0 0.3rem 0.1rem;
      box-sizing: border-box; }
    .content .naval-info-container .ships img {
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3), 2px 2px 2px 2px rgba(0, 0, 0, 0.3), 3px 3px 2px 2px rgba(0, 0, 0, 0.3), 4px 4px 3px 3px rgba(0, 0, 0, 0.2);
      border-top: 1px solid #f6b072;
      border-left: 1px solid #f6b072;
      border-radius: 10px;
      overflow: hidden; }
  .content .naval-info-container .naval-info-container-canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 767px;
    height: 298px; }

.content .naval-info-container-fr {
  background: linear-gradient(to bottom, #f1dbc7 31%, #eeddce 100%);
  position: relative;
  position: block;
  width: 767px;
  height: 300px;
  margin: auto; }
  .content .naval-info-container-fr .floater {
    position: absolute;
    text-align: center; }
    .content .naval-info-container-fr .floater.range {
      font-size: 1.2rem;
      position: absolute;
      left: 47px;
      top: 86px; }
    .content .naval-info-container-fr .floater.arty {
      font-size: 1.2rem;
      position: absolute;
      left: 28px;
      top: 138px; }
    .content .naval-info-container-fr .floater.protection {
      font-size: 1.2rem;
      position: absolute;
      left: 80px;
      top: 230px; }
    .content .naval-info-container-fr .floater.transport {
      font-size: 1.2rem;
      position: absolute;
      left: 230px;
      top: 230px; }
    .content .naval-info-container-fr .floater.movement {
      font-size: 1.2rem;
      position: absolute;
      left: 342px;
      top: 146px; }
    .content .naval-info-container-fr .floater.class {
      font-size: 1.2rem;
      position: absolute;
      left: 362px;
      top: 100px; }
    .content .naval-info-container-fr .floater.name {
      font-size: 1.2rem;
      position: absolute;
      left: 358px;
      top: 70px; }
    .content .naval-info-container-fr .floater.recovery {
      font-size: 1.2rem;
      position: absolute;
      left: 635px;
      top: 111px; }
    .content .naval-info-container-fr .floater.sailed {
      font-size: 1.2rem;
      position: absolute;
      left: 532px;
      top: 241px; }
  .content .naval-info-container-fr .title {
    font-size: 1.5rem;
    position: absolute;
    font-weight: bold;
    text-align: center;
    position: relative;
    top: 9px; }
  .content .naval-info-container-fr .ships {
    display: flex;
    justify-content: space-around;
    margin: 20px 130px 20px 140px;
    text-align: center;
    font-size: 1.2rem; }
    .content .naval-info-container-fr .ships > :nth-child(2) {
      margin-left: 120px; }
    .content .naval-info-container-fr .ships div {
      padding: 0 0 0.3rem 0.1rem;
      box-sizing: border-box; }
    .content .naval-info-container-fr .ships img {
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3), 2px 2px 2px 2px rgba(0, 0, 0, 0.3), 3px 3px 2px 2px rgba(0, 0, 0, 0.3), 4px 4px 3px 3px rgba(0, 0, 0, 0.2);
      border-top: 1px solid #f6b072;
      border-left: 1px solid #f6b072;
      border-radius: 10px;
      overflow: hidden; }
  .content .naval-info-container-fr .naval-info-container-canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 767px;
    height: 298px; }


.header {
  width: 100vw;
  -webkit-user-select: none;
          user-select: none;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.3);
  background: #82202a; }
  .header .subheader {
    padding-top: 0.8rem;
    position: relative; }
    .header .subheader .intro {
      text-align: center;
      color: #fedd02;
      max-width: 100vw;
      max-height: 29px;
      -webkit-transform: scaleY(1.1);
              transform: scaleY(1.1); }
      .header .subheader .intro span {
        font-size: 2.5rem;
        color: #dd0;
        letter-spacing: 0.01rem;
        font-weight: bold; }
        .header .subheader .intro span .tm {
          font-size: 1.4rem;
          position: relative;
          top: -0.75rem;
          left: 0.4rem;
          color: white; }
    .header .subheader .optional-title {
      display: none; }
    .header .subheader .intro2 {
      font-size: 1.4rem;
      color: white;
      text-align: center;
      font-weight: bold;
      padding: 1.4rem 0 0.4rem 0;
      position: relative;
      left: -0.65rem; }
    .header .subheader .flags {
      position: absolute;
      right: 2%;
      bottom: 4.5rem;
      display: flex;
      width: 8%;
      min-width: 150px;
      max-width: 200px;
      justify-content: space-between; }
      .header .subheader .flags img {
        width: 3vw;
        height: calc((47 / 70) * 3vw);
        max-width: 70px;
        max-height: 47px;
        min-width: calc(70px * 0.8);
        min-height: calc(47px * 0.8);
        cursor: pointer;
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5); }
    .header .subheader .translation-attrib {
      -webkit-user-select: text;
              user-select: text;
      color: white;
      position: absolute;
      top: 0.5rem;
      right: 2.2rem;
      font-size: 1rem; }
      @media only screen and (max-width: 1600px) {
        .header .subheader .translation-attrib {
          top: 0.15rem;
          right: 1.6rem;
          font-size: 0.8rem; } }
    .header .subheader .appmenu {
      background: black;
      color: white;
      padding: 0.4rem 0 0.25rem 0;
      font-size: 1.2rem; }
      .header .subheader .appmenu .line1 {
        width: 86%;
        margin: auto;
        display: flex;
        justify-content: space-around;
        padding-bottom: 0.15rem;
        border-bottom: 0.15rem solid #82202a; }
      .header .subheader .appmenu .line2 {
        width: 82%;
        margin: auto;
        display: flex;
        justify-content: space-evenly;
        padding-top: 0.25rem; }
      .header .subheader .appmenu a {
        color: white;
        text-decoration: none;
        font-weight: bold;
        cursor: pointer; }
        .header .subheader .appmenu a:hover {
          color: #dd0; }
        .header .subheader .appmenu a:active {
          color: cyan; }

_:-ms-lang(x),
.header .appmenu .line1,
.header .appmenu .line2 {
  flex: 1 1 auto; }

_:-ms-lang(x),
.header .appmenu .line1 a,
.header .appmenu .line2 a {
  flex: 1 1 auto; }

_:-ms-lang(x),
.header .appmenu .line1 a {
  margin-left: 2.8vw; }

_:-ms-lang(x),
.header .appmenu .line2 a {
  margin-left: 3.2vw; }

_:-ms-lang(x),
.appmenu line1 {
  width: 80% !important; }

.footer {
  position: fixed;
  bottom: 0;
  background: #000;
  color: #DDD;
  text-align: center;
  width: 100vw;
  padding: 5px 0 3px 0;
  font-size: 0.9rem; }
  .footer div {
    margin: 0 auto; }
    @media only screen and (min-width: 1776px) {
      .footer div {
        display: inline; } }

.App {
  text-align: center;
  font-size: 100px; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

