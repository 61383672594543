.content {
    .header-1-image {
        span {
            position: absolute;
            bottom: 22px;
            left: 0;
            font-weight: normal;
            color: red;
            font-size: 1rem;
          }
        .header-1-image-title {
            margin-top: auto;
            position: relative;
            top: 4px;
            font-size: 1.3rem;
        }
        img {
            height: 250px;
            width: auto;
            margin-left: 2rem;
        }
        &.infiltration {
            img {
                border: 1px solid black;
            }
        }
    }
    .header-2-image {
        > span:nth-child(1) {
            position: absolute;
            bottom: 22px;
            left: 0;
            font-weight: normal;
            color: red;
            font-size: 1rem;
          }
        div {
            margin-top: auto;
            position: relative;
            top: 4px;
            font-size: 1.3rem;
        }
        img {
            height: 200px;
            width: auto;
            margin-left: 2rem;
        }
        img:first-of-type {
            margin-top: auto;
            margin-left: 1.5rem;
            height: 70px;
            width: auto;
        }
    }
    .road-signs {
        img {
            height: 30vw;
            max-height: 500px;
            min-height: 300px;
            width: auto;
        }
    }
    .unit-examples {
        width: 98%;
        margin: auto;
        padding-top: 1rem;
        .unit-example {
            text-align: center;
            margin: 1.5rem 0.1rem;
            font-size: 1rem;
            img {
                height: 90px;
                width: auto;
                @media only screen and (min-width: 1900px) {
                    height: 100px;
              }
            }
            :nth-child(2) {
                font-weight: bold;
                
                @media only screen and (min-width: 2000px) {
                        font-size: 1.3rem;
                  }
            }
            :nth-child(3) {
                @media only screen and (min-width: 2000px) {
                    font-size: 1.5rem;
              }
            }

        }
    }
    .movement-phases {
        padding-top: 1.8rem;
        font-size: 1.3rem;
        display: inline-block;
        margin-left: 50%;
        transform: translateX(-50%);
        text-align: center;
        .phases {
            padding-top: 0.3rem;
            font-size: 1.5rem;
            font-weight: bold;
            display: flex;
            flex-direction: row;
            img {
                width: 31px;
                height: 26px;
                margin: 1px 8px 0 10px;
            }
        }
        .axis {
            padding: 0.5rem 1rem 0.4rem 0.7rem;
            background-color: #B8BFC3;
            border-bottom: 2px dotted rgba(0,0,0,0.3);
        }
        .soviet {
            padding: 0.5rem 1rem 0.4rem 0.7rem;
            background-color: #FC9F62;
            border-top: 2px dotted rgba(0,0,0,0.3);
        }
    }
}

