@charset "UTF-8";
.efs-home {
  position: relative;
  text-align: center; }
  .efs-home .new-rules {
    color: blue;
    text-decoration: none; }
    .efs-home .new-rules:hover {
      color: red; }
  .efs-home .new-rules-notice {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px; }
    .efs-home .new-rules-notice .first-para {
      font-size: 110%;
      padding: 15px 0 5px; }
    .efs-home .new-rules-notice a.product-link {
      text-decoration: none; }
      .efs-home .new-rules-notice a.product-link .product-style {
        margin: 10px;
        border: 5px solid black;
        padding: 10px 25px;
        background: linear-gradient(90deg, #7d1d27 0%, #8d2d36 100%); }
        .efs-home .new-rules-notice a.product-link .product-style > div:first-child {
          color: #f3d518;
          font-size: 200%;
          font-weight: bold;
          text-align: center;
          text-shadow: 1px 1px rgba(243, 213, 24, 0.5) -1px -1px rgba(243, 213, 24, 0.5); }
        .efs-home .new-rules-notice a.product-link .product-style > div:nth-child(2) {
          color: white;
          font-size: 103%;
          font-weight: bold;
          letter-spacing: 0.01em; }
        .efs-home .new-rules-notice a.product-link .product-style > div:nth-child(3) {
          color: white;
          font-size: 90%;
          margin-top: 10px;
          font-style: italic;
          text-align: center;
          color: #aff;
          letter-spacing: 0.01em; }
    .efs-home .new-rules-notice div.bsr-link {
      font-size: 140%; }
  .efs-home .update-notice {
    padding: 10px;
    margin: 10px 0 5px 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 120%; }
  .efs-home .notice-box {
    width: 550px;
    margin: 0 auto;
    border: 1px solid black;
    margin-top: 2rem;
    background-color: #f5fbff;
    box-sizing: border-box; }
    .efs-home .notice-box .notice-title {
      font-size: 1.3rem;
      background: black;
      color: white;
      font-weight: bold;
      padding: 1px; }
    .efs-home .notice-box .content-notice {
      padding: 10px;
      font-size: 1.2rem; }
    .efs-home .notice-box .linky {
      position: relative;
      top: -0.35rem; }
      .efs-home .notice-box .linky a {
        color: blue;
        text-decoration: none;
        font-size: 1.5rem; }
        .efs-home .notice-box .linky a:hover {
          color: red; }
    .efs-home .notice-box .please {
      font-size: 0.9rem;
      padding-bottom: 0.5rem; }
  .efs-home .info {
    width: 90%;
    margin: auto;
    text-align: left;
    font-size: 1.25rem; }
    .efs-home .info .tm {
      font-weight: bold;
      color: #900; }
  .efs-home img {
    width: 80%;
    max-width: 1200px;
    height: auto; }
  .efs-home .contents {
    margin-top: 1.8rem;
    background: #fae9b2;
    margin-bottom: 3.5rem;
    display: flex; }
    .efs-home .contents .contents-list {
      padding-top: 0.8rem;
      padding-bottom: 0.1rem;
      margin-left: 20%;
      width: 470px;
      text-align: left; }
      .efs-home .contents .contents-list ul {
        list-style: none; }
      .efs-home .contents .contents-list ul li:before {
        content: "•";
        font-size: 170%;
        /* or whatever */
        padding-right: 5px; }
      .efs-home .contents .contents-list a {
        color: #24e;
        font-size: 1.7rem;
        font-weight: bold;
        text-decoration: none; }
        .efs-home .contents .contents-list a:hover {
          color: red; }
      .efs-home .contents .contents-list .contents-title {
        font-size: 2.8rem;
        font-weight: bold; }
    .efs-home .contents .images {
      padding-top: 0.93%;
      margin-left: 5%; }
      @media only screen and (max-width: 1100px) {
        .efs-home .contents .images {
          display: none; } }
      .efs-home .contents .images img {
        width: auto;
        height: 380px; }
