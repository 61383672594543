.unit-size {
    margin-left: 5rem;
    border: 1px solid black;
    border-collapse: collapse;
    font-size: 1.3rem;
    td {
        border: 1px solid black;
        &:nth-child(1), &:nth-child(2) {
            text-align: center;
            font-weight: bold;
            padding: 0 1rem;
        }
        &:nth-child(3) {
            padding: 0.1rem 1rem 0.1rem 1rem;
        }
    }
}

.unit-type {
    font-size: 1.4rem;
    img {
        width: auto;
        height: 25px;
    }
    td:nth-child(1), td:nth-child(3), td:nth-child(5), td:nth-child(7) {
        padding-top: 5px;
    }
    td:nth-child(2), td:nth-child(4), td:nth-child(6), td:nth-child(8) {
        padding-right: 35px;
    }
}
.note {
    font-size: 1.3rem;
}

.red {
    color: red;
    margin-left: 2px;
    font-weight: bold;
}